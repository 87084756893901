import React from "react"
import { CSSTransition } from "react-transition-group"
import "./modal.scss"

const Modal = ({ visible, closeModal, children, ModalFooter }) => {
  return (
    <CSSTransition
      in={visible}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      classNames="modal"
    >
      <div className="modal" onClick={closeModal}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <ModalFooter />
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal
