import React, { useState } from "react"
import { Collapse } from "react-collapse"
import { useLocation } from "@reach/router"
import Switch from "rc-switch"
import Cookies from "universal-cookie"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useTranslation } from "react-i18next"

import Modal from "../Modal/Modal"
import styles from "./CookieModal.module.scss"
import constants from "../../constants"
import { isBrowser } from "../../utils/isBrowser"

const CookieModal = ({ isVisible, closeModal }) => {
  const { t } = useTranslation()
  const cookies = new Cookies()
  const location = useLocation()

  const [switchValue, setSwitchValue] = useState(
    cookies.get(constants.GA_COOCKIE_KEY) === "true"
  )

  const [visbleCollapse, setVisibleCollapse] = useState({
    required: false,
    others: false,
  })

  const toggleCollapse = key => {
    setVisibleCollapse({ ...visbleCollapse, [key]: !visbleCollapse[key] })
  }

  const updateCookies = () => {
    cookies.set(constants.GA_COOCKIE_KEY, switchValue, { path: "/" })
    cookies.set(constants.FB_PIXEL_COOCKIE_KEY, switchValue, { path: "/" })
  }

  const removeMarketingCookies = () => {
    const toBeSaved = [
      constants.GA_COOCKIE_KEY,
      constants.FB_PIXEL_COOCKIE_KEY,
      constants.ADULT_KEY,
    ]
    Object.keys(cookies.getAll()).forEach(name => {
      if (!toBeSaved.includes(name)) {
        cookies.remove(name, { path: "/", domain: document.domain })
      }
    })
  }

  const applyChanges = () => {
    updateCookies()
    if (switchValue) {
      initializeAndTrack(location)
    } else {
      removeMarketingCookies()
      isBrowser() && window.location.reload()
    }
    closeModal()
  }

  const toggleSwitch = () => {
    setSwitchValue(!switchValue)
  }

  const ModalFooter = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="btn-white" onClick={applyChanges}>
          {t("cookie-modal:btn")}
        </button>
      </div>
    )
  }

  const arrowClass = isOpen => {
    const classes = [styles.arrow]
    if (isOpen) {
      classes.push(styles.open)
    }
    return classes.join(" ")
  }

  return (
    <Modal
      visible={isVisible}
      closeModal={closeModal}
      ModalFooter={ModalFooter}
    >
      <div className={styles.container}>
        <div className={styles.descContainer}>
          <h4>{t("cookie-modal:title")}</h4>
          <h1 className="box">{t("cookie-modal:subtitle")}</h1>
          <div className={styles.item}>
            <div
              className={styles.collapsable}
              onClick={() => toggleCollapse("required")}
            >
              <div>
                <img
                  src="/img/arrow-left.svg"
                  alt="arrow"
                  className={arrowClass(visbleCollapse.required)}
                />
              </div>
              <div className={styles.desc}>
                <h3>{t("cookie-modal:required")}</h3>
                <Collapse isOpened={visbleCollapse.required}>
                  {t("cookie-modal:requiredDesc")}
                </Collapse>
              </div>
            </div>
            <div className={styles.aciveBtn}>
              <div>{t("cookie-modal:activeBtn1")}</div>
              <div>{t("cookie-modal:activeBtn2")}</div>
            </div>
          </div>
          <div className={styles.item}>
            <div
              className={styles.collapsable}
              onClick={() => toggleCollapse("others")}
            >
              <div>
                <img
                  src="/img/arrow-left.svg"
                  alt="arrow"
                  className={arrowClass(visbleCollapse.others)}
                />
              </div>
              <div className={styles.desc}>
                <h3>{t("cookie-modal:optional")}</h3>
                <Collapse isOpened={visbleCollapse.others}>
                  {t("cookie-modal:optionalDesc")}
                </Collapse>
              </div>
            </div>

            <Switch checked={switchValue} onClick={toggleSwitch} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CookieModal
